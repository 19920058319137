:root {
    --clr--off-black: #111414;
    --clr--mid-gray: #747474;
    --clr--light-gray: #A8A8A8;
    --clr--blue: #0089D8;
    --clr--pink: #F295CE;
    --clr--rorange: #FF5D36;
    --clr--green: #00AF9B;
    --clr--yellow: #FFD140;
}
* {
    box-sizing: border-box;
}
html, body {
    font-size: 18px;
}
body {
    background-color: white;
}
.logo-letter {
    path: currentColor;
    fill: currentColor;
}

h1 {
    text-align: left;
    font-size: 1.75rem;
    display: block;
    font-weight: 300;
    padding-bottom: 3rem;
    width: 100%;
}
svg {
    width: 100%;
    max-width: 20rem;
}


header {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    gap: 2rem;
}
.App {
    max-width: 42rem;
    margin: 0 auto;
}

@media only screen and (max-width: 768px) {
    header {
        display: block;
        box-sizing: content-box;
    }
    .form-container, .sigTemplateContainer {
        margin: 0 15px 15px;
    }
}

.form-container {
    padding: 0px;
    margin: 0px 0rem 0 0;
    width: 100%;
    max-width: 400px;
    
}
.form-container p {
    margin-top: 3rem;
}
.sigTemplateContainer {
    /*background: white;
    padding: 50px;*/
}
.sig-container {
    background: white;
    border: solid 1px var(--clr--light-gray);
    padding: 1.5rem;
    margin: 0;
    box-shadow: 1px 2px 5px 0px rgba(0,0,0,0.20);
}

.inputGroup {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
}
.inputGroup label {
    font-size: 0.9rem;
    font-weight: bold;
    min-width: 140px;
}
.inputGroup input,
.inputGroup select,
.inputGroup textarea {
    width: 100%;
    border: solid 1px var(--clr--light-gray);
    border-radius: 2px;
    padding: 10px;
    font-size: inherit;
    font-family: inherit;
    resize: none;
}
.inputGroup input:focus {
    outline: none;
    border-color: #ffb30c;
}
label.active {
    color: #ffb30c;
}

.member-button-wrap {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;

}
.member-button {
    border: solid 1px var(--clr--light-gray);
    padding: 0.25rem 1rem;
    background-color: transparent;
    cursor: pointer;
    transition: all 150ms ease;
}
.member-button:hover {
    color: var(--clr--yellow);
    background-color: var(--clr--off-black);
    border-color: var(--clr--off-black);
}

.people-presets {
    display: flex;
    align-items: center;
    padding-bottom: 2rem;
    gap: 0rem;
}
.people-presets h4 {
    margin: 0;
    min-width: 140px;
    padding-right: 1rem;
}